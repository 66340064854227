html, body {
  position: relative;
  width: 100%;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif;
}
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ce0820;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #2f9f32;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #7E8299;
  --gray-dark: #3F4254;
  --primary: #0B437B;
  --primary-light: #0990ec26;
  --primary-dark:#037ed1;
  --primary-light-blue:#0996f552;
  --secondary: #D8071E ;
  --success: #1BC5BD;
  --info: #8950FC;
  --warning: #FFA800;
  --danger: #F64E60;
  --light: #F3F6F9;
  --dark: #000000;
  --white: #ffffff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family: 'Inter', sans-serif;
  --main-yellow-color:#d99112; 
}

.MuiInputBase-root{
  width: 100%;
}

.formSection .btn.btn-primary {
  background-color: var(--primary);
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  padding: 12px 25px;
  text-transform: uppercase;
  font-family: var(--font-family);
  width: 100%;
}
.formSection .btn.btn-secondary {
  background: var(--secondary);
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  padding: 12px 25px;
  text-transform: uppercase;
  font-family: var(--font-family);
  width: 100%;
}

.formSection .form-group{
  margin-bottom: 15px;
  position: relative;
}

.formSection .form-group .forgot-link{
  position: absolute;
  right: 0;
  font-size: 12px;
  color: #000;
  font-weight: 500;
  text-decoration: unset;
  cursor: pointer;
}

.formSection .form-group label,
.formSection .form-group label .MuiFormControlLabel-label{
  color: #000;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin-bottom: 8px;
  font-family: var(--font-family);
}


.formSection .form-group label .MuiFormLabel-asterisk{
  color: var(--red);
  padding-left: 2px;
}


.formSection .form-group label .MuiFormControlLabel-label{
  margin-bottom: 0;
}

.formSection .form-group input,
.formSection .form-group .MuiSelect-select,
.MuiList-root li{
  background-color: #fff;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px 15px;
  font-family: var(--font-family);
  font-weight: 600;
}

.formSection .form-group .MuiOutlinedInput-notchedOutline{
  border: 1px solid #d9d9d9;
  
}

.formSection .reg-text{
  font-size: 13px;
  color: #000;
  margin: 0;
}

.formSection .reg-text span{
  cursor: pointer;
  text-decoration: none;
  color: var(--secondary);
}


.formSection .reg-text span:hover,
.formSection .form-group .forgot-link:hover{
  text-decoration: underline;
}

.form-group .eye-icon svg{
  fill: var(--primary);
}

.form-group .check-icon svg{
  fill: #707070;
}

.form-group .check-icon.Mui-checked svg{
  fill: var(--primary);
}

.errorMsg {
  color: var(--red);
  font-size: 12px;
  margin-top: 2px;
  font-weight: 500;
}

.modal-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--primary);
  background: #fff;
  padding: 3rem;
  border-radius: 10px;
  color: var(--primary);
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-thumb {
  background: var(--primary);
}
::-webkit-scrollbar-track {
  background: #d6d6d6;
}
.BusinessService_box {
  margin-bottom: 30px;
  position: relative;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  height: auto;
  max-height: 300px !important;
}

.top-left-right-head{
  width: 100%;
}

.top-left-right-head .heading{
  color: var(--primary);
  font-size: 22px;
  position: relative;
  width: auto;
  display: inline-block;
  font-weight: 500;
  margin: 45px auto 35px; 
}

.top-left-right-head  .heading:before {
  position: absolute;
  content: '';
  border: 1px solid;
  width: 40px;
  left: -50px;
  top: 50%;
  border-radius: 10px;
}
.top-left-right-head .heading:after {
  position: absolute;
  content: '';
  border: 1px solid;
  width: 40px;
  right: -50px;
  top: 50%;
  border-radius: 10px;
}

.App .MuiLoadingButton-loading span{
 color: transparent;
}

.App .btn .MuiLoadingButton-loadingIndicator{
  color: var(--white);
}

@media (max-width: 567px){
  .top-left-right-head .heading{
    font-size: 19px;
    margin: 30px auto 15px; 
  }
  
  .top-left-right-head  .heading:before,
  .top-left-right-head .heading:after {
    content: none;
  }
}