.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 600px){
  
.home-content .MuiContainer-maxWidthLg {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
}
