.HeaderUI .log {
    width: 40px;
    height: 20px;
}
.HeaderUI .logobg{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app_theme_color2);
    margin-left: '50px';
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
}
.HeaderUI .title-text {
    display: flex;
    flex-direction: column;
    color: #000;
    text-align: left;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
}
.SearchBg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    width: fit-content;
}

.SearchTextField {
    border: 1px solid #bdbdbf;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
    padding-left: 8px;
    padding-right: 10px;
    margin-right: 20px;
}
.searchIcon {
    height: 18px;
    width: 18px;
}
.ProductName {
   font-size: 13px;
   color: #33373d;
   font-weight: 500;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   overflow: hidden;
   text-overflow: ellipsis;
}
.PriceName {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    align-self: flex-start;
}
.list {
    background-color: aqua;
}
.viewline {
    border-bottom: 1px solid var(--app_theme_color);
    display: flex;
    width: '100%';   
}
.powered_by_text {
    font-size: 12px;
    color: gray;
    font-weight: 500;
    align-self: center;
}
.iar_text {
    font-size: 12px;
    color: #e20682;
    font-weight: 500;
    margin-left: 5px;
    align-self: center;
}
.Location {
    font-size: 12px;
    color: #000;
    font-weight: 500;
}

.ProductId {
    font-size: 13px;
    color: #33373d;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
}
 
/* Microcart Demo Design style */


.MainContainer {
    display: 'flex';
    flex-direction: column;
    flex: 1;
    height: '100vh';
    overflow: 'auto';
}
.headerline {
    border-bottom: 6px solid var(--app_theme_color);
    display: flex;
    flex: 1;
}
.viewline {
    border-bottom: 1px solid var(--view_line_color);
    display: flex;
    flex: 1;
}

.grid {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.class3{
 min-height: 30;
}

.MainContainer .logoimg {
    margin-left: 85px;
    width: 80px;
    height: 30px;
}

@media(max-width:767px) {
.MainContainer .logoimg {
        margin-left: 20px;
        width: 70px;
        height: 25px;
    }
}

.searchBackground {
    display: flex;
    flex: 0.6;
    margin-left: 50px;
}

@media(max-width:900px) {
.searchBackground {
        display: flex;
        flex: 1;
        margin-right: 20px;
    }
}

.listspace {
margin-right: 65px;
margin-top: 20px;
}

@media(max-width:900px) {
.listspace {
        margin-right: 20px;
        margin-top: 20px;
    }
}

.gridspace {
    margin-left: 85px
}

@media(max-width:900px) {
    .gridspace {
        margin-left: 20px;
    }
}

.app_name {
    color: '#33373d';
    font-size: 16px;
    font-weight: 700;
    align-self: center;
    margin-left: 15px;
}

@media(max-width:767px) {
.app_name {
        color: '#33373d';
        font-size: 15px;
        font-weight: 700;
        align-self: center;
        margin-left: 8px;
    }
}